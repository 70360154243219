<template>
  <div id="login">
    <div class="login-box">
      <div class="title">浩云运营后台</div>
      <el-form :model="form" :rules="rule" ref="form">
        <el-form-item prop="username">
          <el-input
            type="text"
            v-model="form.username"
            placeholder="账号"
            clearbale
          >
            <i slot="prefix" class="iconfont icon-user"></i>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            type="password"
            v-model="form.password"
            placeholder="密码"
            show-password
            clearbale
            @keyup.enter.native="handleSubmit('form')"
          >
            <i slot="prefix" class="iconfont icon-lock"></i>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            class="login-btn"
            @click="handleSubmit('form')"
            >登录</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import account from "@/api/account";

export default {
  name: "login",
  data() {
    return {
      loading: false,
      btnLoginText: "登录",
      form: {
        username: "",
        password: "",
      },
      rule: {
        username: [
          { required: true, message: "请输入账号！", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入密码！", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    // 登录
    handleSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loding = true;
          this.btnLoginText = "登录中";
          account
            .getToken(this.form)
            .then((res) => {
              localStorage.setItem("auth.token", res.results.token);
              this.$router.push({ name: "tenant" });
            })
            .catch((error) => {
              this.$message({
                type: "warning",
                message: error.message,
              });
            });
          this.loading = false;
          this.btnLoginText = "登录";
        }
      });
    },
    handleGoPage(name) {
      this.$router.push({
        name: name,
      });
    },
  },
};
</script>

<style lang="stylus">
#login
  height 100%
  background-color #1ba0fc
  display flex
  align-items center
  justify-content center

  .login-box
    background-color #ffffff
    width 400px
    height 300px
    padding 40px
    margin-left auto
    margin-right auto
    border-radius 5px

    .title
      text-align center
      font-size 40px
      margin-bottom 40px

    .iconfont
      padding 0 5px

    .login-btn
      width 100%
</style>
