import api from "@/api";

const account = {
  getToken(data) {
    return api.post(`/account/token`, data);
  },
  getInformation() {
    return api.get(`/account/information`);
  },
  changePassword(data) {
    return api.patch(`/account/password`, data);
  },
};

export default account;
